import { FC } from 'react';
import { IModalProps } from './types';
import { Spacing } from 'shared/ui/atoms/spacing';
import { Typography } from 'shared/ui/atoms/typography';
import { ModalHeader } from 'shared/ui/organisms/modal';
import { FlexBox } from '../../shared/ui/atoms/flexbox';
import { SvgIcon } from '../../shared/ui/atoms/svg-icon';
import { StyledImg, StyledModal } from './styles';
import { Button } from '../../shared/ui/atoms/button';
import { useNavigate } from 'react-router-dom';
import { RouteE } from 'shared/config/navigation';
import { modalRegistry } from '../../shared/modal/providers/modal.registry';

const Ui: FC<IModalProps> = ({ type, closeModal, closeParentModal }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        modalRegistry.closeAll();
        closeParentModal?.();
        navigate(RouteE.PROFILE_SUBSCRIPTION);
    };
    return (
        <StyledModal>
            <ModalHeader onClose={closeModal}>
                <StyledImg>
                    <FlexBox alignItems='center' justifyContent='center'>
                        <SvgIcon width={28} variant='interface' icon='warning' color='errors' />
                    </FlexBox>
                </StyledImg>
            </ModalHeader>
            <Spacing mb='13px'>
                <Typography tag='h3' size='h_2' bold center>
                    Slight snag...
                </Typography>
            </Spacing>
            <Typography color='grey2' size='p_small' medium>
                {type === 'trial' ? (
                    <>
                        The trial period has come to an end, and you will need to choose a plan to continue enjoying
                        our app.
                    </>
                ) : (
                    <>
                        You have reached the maximum number of {type} allowed.  If you would like to add more,
                        please upgrade your plan.
                    </>
                )}
            </Typography>
            <Spacing mt='24px'>
                <FlexBox>
                    <Button
                        onClick={handleClick}
                        stretch
                    >
                        <SvgIcon icon='checkmark' color='white' />
                        Change Plan
                    </Button>
                    <Button style={{ marginLeft: '24px' }} variant='secondary-grey' onClick={closeModal} stretch>
                        <SvgIcon icon='cross' />
                        No, Thanks
                    </Button>
                </FlexBox>
            </Spacing>
        </StyledModal>
    );
};

export default Ui;

import styled, { css } from 'styled-components';
import { transition } from 'shared/mixins/transition';
import { media } from 'shared/mixins/media';

interface ModalOpts {
    fullScreen?: boolean;
    $size?: 'sm' | 'md' | 'lg';
}

export const StyledModalFooter = styled.div<ModalOpts>`
    width: 100%;
    padding: 0 20px 20px;

    & button {
        white-space: nowrap;
    }

    & > * {
        flex: 1;
    }

    & > * + * {
        margin-left: 10px;
    }

    ${({ fullScreen }: ModalOpts) => fullScreen && css`
        position: fixed;
        bottom: 0;
        left: 0;
        height: 100px;
        padding: 20px 20px 40px 20px;

        ${media.sm`
          position: static;
          height: auto;
          border: none;
          padding: 0 20px 20px;
        `};
    `}
`;

const heightFillMixin = css`
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    min-height: 100%;
`;

export const StyledModalRoot = styled.div<ModalOpts>`
    position: relative;
    width: 100%;
    height: calc(100% - 48px);
    margin: auto 0 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);

    ${media.md(css`
      height: fit-content;
      min-width: 660px;
      border-radius: 20px;
    `)}
    
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
    `}

    ${({ fullScreen }) => fullScreen && css`
        width: 100vw;
        height: 100vh;
        border-radius: 0;

        ${heightFillMixin};

        ${media.md(css`
            height: 100% !important;
            border-radius: 0;
        `)}
        
        & ${StyledModalContent} {
            ${heightFillMixin};
        }
    `}

    ${({ fullScreen }) => !fullScreen && css`
        ${media.md`
          width: fit-content;
          margin: auto;
        `}
    `}

    ${({ $size }) => $size === 'md' && media.md(css`
          width: 660px;
    `)}
`;

export const StyledModalContent = styled.div<ModalOpts>`
    position: relative;
    background-clip: padding-box;
    outline: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-height: calc(100vh - 96px);
    overflow: hidden auto;
    padding: 49px 16px 40px;

    ${media.md(css`
        padding: 50px;
        height: 100%;
        max-height: unset;
        overflow: unset;
    `)}
    
    & > div {
        flex: 1;
    }
    
    & form ${StyledModalFooter} {
        padding: 20px 0 0;

        ${({ fullScreen }) => fullScreen && css`
            padding: 20px 20px 40px;
        `}
    }
`;

export const StyledModalHeader = styled.div`
    margin: 0 0 16px;
    position: relative;

    ${media.md(css`
        margin: 0 0 25px;
    `)}
    & > svg {
        cursor: pointer;
        position: absolute;
        top: -10px;
        right: 15px;

        ${transition(['color'])}
        &:hover {
            color: ${({ theme }) => theme.colors.black2};
        }
    }
`;

export const StyledModalBody = styled.div`
    padding: 20px;
`;

import { ModalVariantT, ModalRegistryEntityItem, ModalRegistryItem } from '../modal.types';
import { ModalEntity } from './modal.entity';

type Listener = (modals: ModalRegistryEntityItem[]) => void;

class ModalRegistry {
    private modals: Map<string, ModalRegistryEntityItem> = new Map();

    private opened: string[] = [];

    private listeners: Listener[] = [];

    onModalStateChanged(id: ModalVariantT, state = false) {
        const modal = this.get(id);
        if (modal) {
            if (state) {
                this.opened.push(id);
            } else {
                this.opened = this.opened.filter(i => i !== id);
            }
            this.emit();
        }
    }

    register<T>(modalArgs: ModalRegistryItem) {
        const modal = new ModalEntity<T>({
            ...modalArgs,
            onOpen: () => this.onModalStateChanged(modalArgs.id, true),
            onClose: () => this.onModalStateChanged(modalArgs.id, false)
        });
        this.modals.set(modal.id, modal);
        this.emit();
        return modal;
    }

    emit() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        const modals = this.opened.map(id => this.modals.get(id)).filter(Boolean);
        // @ts-ignore
        this.listeners.forEach(cb => cb(modals));
    }

    subscribe(cb: Listener) {
        this.listeners.push(cb);
    }

    unsubscribe(cb: Listener) {
        this.listeners.filter(l => l !== cb);
    }

    get(id: ModalVariantT) {
        return this.modals.get(id);
    }

    closeAll() {
        this.opened.forEach(id => {
            const modal = this.get(id as ModalVariantT);
            if (modal) {
                modal.close();
            }
        });
        this.emit();
    }
}

export const modalRegistry = new ModalRegistry();

import styled from 'styled-components';
import { Modal } from 'shared/ui/organisms/modal';
import { media } from 'shared/mixins/media';

export const StyledModal = styled(Modal)`
    ${media.md`
        width: 100%;
        max-width: 500px;
        min-width: 320px;
    `}
`;

export const StyledImg = styled.div`
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
    
    & div {
        width: 60px;
        height: 100%;
        background: rgba(255, 106, 85, 0.3);
        border-radius: 16px;
    }
`;

/**
 * Libs
 */
import { ReactNode, useMemo } from 'react';
import { SvgIcon } from '../../atoms/svg-icon';

/**
 * Constants
 */
import { ModalContext, useModalContext } from './modal.provider';

/**
 * Styles
 */
import {
    StyledModalHeader, StyledModalRoot, StyledModalContent, StyledModalBody, StyledModalFooter
} from './modal.styles';

interface Props {
    children: ReactNode;
    className?: string;
    testId?: string;
}

interface RootModalProps extends Props {
    fullScreen?: boolean;
    size?: 'sm' | 'md' | 'lg';
}

interface HeaderProps extends Props {
    onClose?: () => void;
}

export const Modal = ({ testId, children, className, fullScreen, size }: RootModalProps) => {
    const contextValue = useMemo(() => (
        { fullScreen: !!fullScreen }
    ), [fullScreen]);

    return (
        <ModalContext.Provider value={contextValue}>
            <StyledModalRoot
                $size={size}
                fullScreen={!!fullScreen}
                className={className}
                data-test-id={testId ?? 'modal'}
            >
                <StyledModalContent fullScreen={!!fullScreen}>
                    <div>
                        {children}
                    </div>
                </StyledModalContent>
            </StyledModalRoot>
        </ModalContext.Provider>
    );
};

export const ModalHeader = ({ children, className, testId, onClose }: HeaderProps) => (
    <StyledModalHeader className={className} data-test-id={testId ?? 'modal-header'}>
        {onClose && (
            <SvgIcon icon='cross' color='grey2' onClick={onClose} />
        )}
        {children}
    </StyledModalHeader>
);

export const ModalBody = ({ children, className, testId }: Props) => (
    <StyledModalBody className={className} data-test-id={testId ?? 'modal-body'}>
        {children}
    </StyledModalBody>
);

export const ModalFooterActions = ({ children, className, testId }: Props) => {
    const { fullScreen } = useModalContext();

    return (
        <StyledModalFooter className={className} fullScreen={fullScreen} data-test-id={testId ?? 'modal-footer-actions'}>
            {children}
        </StyledModalFooter>
    );
};
